// Background Colors

// Primary bg
$primary: #FFF !default; // Bootstrap variable

// Secondary bg
$secondary: #E4E4E4 !default; // Bootstrap variable

// Tertiary bg
$tertiary: #505050 !default;

// Text Colors

// Primary
$primary-text: #000;

// Secondary
$secondary-text: #808080;

// Tertiary
$tertiary-text: #FFF;
